import {SocialNetworks} from '@wix/panda-js-utils'
import {SHARE_BUTTON_NAMES} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React, {KeyboardEvent} from 'react'
import {Facebook} from '../../../icons/Facebook'
import {Linkedin} from '../../../icons/Linkedin'
import {X} from '../../../icons/X'
import {useMobileFontOverrides} from '../../hooks/use-mobile-font-overrides'
import {getShareUrls} from '../../services/share'
import s from './social-bar.scss'
import {SocialBarProps} from '.'

const DEFAULT_SPACING = 14

const REFERENCE_FONT_SIZE = 22

export const SocialBar = ({
  t,
  hideTitle,
  spacing = DEFAULT_SPACING,
  row,
  className,
  iconFontSize,
  eventUrl,
  onShare,
  eventId,
  newColors = true,
}: SocialBarProps) => {
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()
  const shareUrls = getShareUrls(eventUrl, t)

  const facebookShare = () => onShare && onShare(eventId, SHARE_BUTTON_NAMES.FACEBOOK)

  const xShare = () => onShare && onShare(eventId, SHARE_BUTTON_NAMES.X)

  const linkedInShare = () => onShare && onShare(eventId, SHARE_BUTTON_NAMES.LINKEDIN)

  const handleKeyPress = (event: KeyboardEvent<HTMLAnchorElement>, handler: Function) => {
    if (event.key === 'Enter') {
      handler()
    }
  }

  return (
    <div
      data-hook="social-bar"
      className={classNames({[s.row]: row, [s.studioColor]: newColors && !mobileFontOverridesEnabled}, className)}
    >
      {!hideTitle && <div className={row ? s.rowTitle : s.title}>{t('shareEventText')}</div>}
      <div className={s.icons}>
        <a
          target="_blank"
          href={shareUrls[SocialNetworks.Facebook]}
          className={s.icon}
          data-hook="social-bar-icon-facebook"
          onClick={facebookShare}
          onKeyPress={event => {
            handleKeyPress(event, facebookShare)
          }}
          tabIndex={0}
          aria-label={t('a11y.shareButtonLabel', {button: 'Facebook'})}
          rel="noreferrer"
        >
          <Facebook
            size={undefined}
            width={`${getIconSize(9, iconFontSize)}px`}
            height={`${getIconSize(16, iconFontSize)}px`}
          />
        </a>
        <a
          target="_blank"
          href={shareUrls[SocialNetworks.Twitter]}
          style={{margin: `0 ${spacing}px`}}
          className={s.icon}
          data-hook="social-bar-icon-x"
          onClick={xShare}
          onKeyPress={event => {
            handleKeyPress(event, xShare)
          }}
          tabIndex={0}
          aria-label={t('a11y.shareButtonLabel', {button: 'X'})}
          rel="noreferrer"
        >
          <X width={`${getIconSize(18, iconFontSize)}px`} height={`${getIconSize(14, iconFontSize)}px`} />
        </a>
        <a
          target="_blank"
          href={shareUrls[SocialNetworks.LinkedIn]}
          className={s.icon}
          data-hook="social-bar-icon-linkedin"
          onClick={linkedInShare}
          onKeyPress={event => {
            handleKeyPress(event, linkedInShare)
          }}
          tabIndex={0}
          aria-label={t('a11y.shareButtonLabel', {button: 'LinkedIn'})}
          rel="noreferrer"
        >
          <Linkedin
            size={undefined}
            width={`${getIconSize(19, iconFontSize)}px`}
            height={`${getIconSize(16, iconFontSize)}px`}
          />
        </a>
      </div>
    </div>
  )
}

const getIconSize = (defaultActualSize: number, requiredSize?: number) =>
  requiredSize ? Math.round(((defaultActualSize * requiredSize) / REFERENCE_FONT_SIZE) * 100) / 100 : defaultActualSize
